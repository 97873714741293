body {
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    font-family: 'Akkurat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.material-icons {
    font-size: 1em;
}

header + .MuiCollapse-vertical > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .form-group.field-object:first-of-type {
    line-height: 0;
}

.form-group.field-string + .form-group.field-string {
    margin-top: 1em;
}

.MuiCollapse-entered .MuiCollapse-entered {
    padding-bottom: 8px;
}

.field-array .field-array > .MuiFormControl-root > .form-group.field.field-object:not(:first-of-type) {
    margin-top: 8px;
}

/* hack for iframe overlay in dev */
iframe {
    pointer-events: none;
}

#header--container {
    height: 64px;
}

@font-face {
    font-family: 'Akkurat';
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-light.eot');
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-light.eot?#iefix') format('embedded-opentype'),
        url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-light.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat';
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-lightitalic.eot');
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-lightitalic.eot?#iefix') format('embedded-opentype'),
        url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-lightItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Akkurat';
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.eot');
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.eot?#iefix') format('embedded-opentype'),
        url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat';
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.eot');
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.eot?#iefix') format('embedded-opentype'),
        url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Akkurat';
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.eot');
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.eot?#iefix') format('embedded-opentype'),
        url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat';
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot');
    src: url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot?#iefix') format('embedded-opentype'),
        url('https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
