.section--search-vm {
    position: relative;
    padding-bottom: 3em;
}
.section--search-vm .search__title {
    text-align: center;
}
.section--search-vm .vm-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    padding: 0 2rem;
}
.section--search-vm .vm-wrapper kit-vm-card {
    position: relative;
    font-size: 1rem;
}
.section--search-vm .vm-wrapper kit-vm-card * {
    font-family: inherit;
}
.section--search-vm .vm-wrapper kit-vm-card *, .section--search-vm .vm-wrapper kit-vm-card *::before, .section--search-vm .vm-wrapper kit-vm-card *::after {
    box-sizing: content-box;
}
@media (min-width: 641px) {
    .section--search-vm .vm-wrapper kit-vm-card.wide > .vm-card .vm-card-footer {
        max-width: 50%;
        left: 25%;
   }
    .section--search-vm .vm-wrapper kit-vm-card.wide > .vm-card:hover .vm-card-footer {
        left: 24%;
   }
}
@media (max-width: 719px) {
    .section--search-vm .vm-wrapper kit-vm-card {
        width: 100%;
   }
    .section--search-vm .vm-wrapper kit-vm-card .vm-card {
        width: 100% !important;
   }
}
@media (min-width: 1600px) {
    .section--search-vm .vm-wrapper {
        margin: 0 auto;
   }
}
@media (max-width: 1023px) {
    .section--search-vm .vm-wrapper {
        flex-wrap: wrap;
   }
    .section--search-vm .vm-wrapper kit-vm-card:not(:last-child) {
        margin-bottom: 2.5em;
   }
}
.section--search-vm .vm-wrapper .vm-card .vm-card-footer {
    max-width: 90%;
}
@media (max-width: 1023px) and (min-width: 641px) {
    .section--search-vm .vm-wrapper .vm-card {
        width: 600px !important;
   }
    .section--search-vm .vm-wrapper .vm-card .vm-card-footer {
        max-width: 50%;
        left: 25%;
   }
    .section--search-vm .vm-wrapper .vm-card:hover .vm-card-footer {
        left: 24%;
   }
}
.section--search-vm > .card-button {
    margin-top: 1em;
}
.buttongroup {
    list-style: none;
}
.template--vm-search article {
    background: #FAF8F2;
}
@media (min-width: 1629px) {
    .template--vm-search article {
        margin: 0 auto;
   }
}
.template--vm-search article header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    max-width: 1252px;
    margin: 0 auto;
    padding: 20px 14px;
}
.template--vm-search article header h1, .template--vm-search article header h2 {
    position: relative;
    width: 100%;
    max-width: 640px;
    margin: 1em 0 0;
    text-align: center;
}
.template--vm-search article header h2 {
    margin: 1em 0 2em;
    font-size: 1em;
    font-weight: normal;
}
.template--vm-search article header .beta-chip {
    position: absolute;
    top: .25rem;
    margin-left: .5rem;
    border: 1px solid #3A2E2D;
    border-radius: 16px;
    padding: 4px 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.14em;
    color: #3A2E2D;
    text-decoration: none;
}
.template--vm-search article header .filters {
    display: flex;
    align-items: center;
    width: 100%;
    padding: .75rem 0;
    border-top: 1px solid #d7d5dd;
    border-bottom: 1px solid #d7d5dd;
}
.template--vm-search article header .filters h3 {
    font-size: 0.8rem;
    margin: 0.3rem 1rem 0 0;
}
.template--vm-search article header .filters form {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: .2rem;
    font-size: .8rem;
}
.template--vm-search article header .filters form fieldset {
    display: flex;
}
.template--vm-search article header .filters form input[type="checkbox"] {
    margin-top: 0;
}
.template--vm-search article header .filters form input:not(:first-child) {
    margin-left: 1rem;
}
.template--vm-search article header .filters form .buttongroup--options-owner {
    position: relative;
}
.template--vm-search article header .filters form .buttongroup--options-owner nav {
    display: block;
    padding: 0.75em 0.5em;
    position: absolute;
    z-index: 2001;
    top: 10px;
    margin-top: 1.5em;
    margin-right: -1em;
    margin-left: -1em;
    background-color: white;
    border: 1px solid black;
    border-radius: 2px;
    box-shadow: 0 1.5px 2px rgba(0, 0, 0, 0.06), 0 1.5px 1.5px rgba(0, 0, 0, 0.06);
    right: 0;
    left: auto;
}
@media (min-width: 360px) {
    .template--vm-search article header .filters form .buttongroup--options-owner nav {
        width: 212px;
   }
}
@media (min-width: 400px) {
    .template--vm-search article header .filters form .buttongroup--options-owner nav {
        width: 238px;
   }
}
@media (min-width: 640px) {
    .template--vm-search article header .filters form .buttongroup--options-owner nav {
        width: 207px;
   }
}
@media (min-width: 720px) {
    .template--vm-search article header .filters form .buttongroup--options-owner nav {
        width: 212px;
   }
}
@media (min-width: 1280px) {
    .template--vm-search article header .filters form .buttongroup--options-owner nav {
        width: 238px;
   }
}
.template--vm-search article header .filters form .form__input {
    background: none;
}
.template--vm-search article header .filters form .form__input input[type=text] {
    min-width: 14rem;
    border: none;
    background: none;
}
.template--vm-search article header .filters form .form__input .c-input-icon__icon {
    top: -4px;
}
@media (min-width: 824px) {
    .template--vm-search article header .filters form .form__input::after {
        position: absolute;
        height: 3.8rem;
        top: -1rem;
        left: -1rem;
        content: '';
        border-left: 1px solid #d7d5dd;
   }
}
@media (max-width: 823px) {
    .template--vm-search article header .filters {
        padding: .25rem 0;
   }
    .template--vm-search article header .filters, .template--vm-search article header form {
        flex-wrap: wrap;
   }
}
.template--vm-search article .section--search-vm {
    background: #FAF8F2;
}
.template--vm-search article .section--search-vm .vm-wrapper {
    flex-wrap: wrap;
}
.template--vm-search article .section--search-vm .vm-wrapper .vm-card {
    padding-bottom: 6em;
}
.template--vm-search article .section--footer-vm {
    padding-top: 1em;
    background: #F2F2F2;
}
.template--vm-search article .section--footer-vm .vm-footer {
    max-width: 704px;
    margin: 0 auto 1.5em;
}
@media (max-width: 1023px) {
    .template--vm-search article .section--footer-vm .vm-footer {
        padding: 0 1.5rem;
   }
}
.template--vm-search article .section--footer-vm .vm-footer .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 639px) {
    .template--vm-search article .section--footer-vm .vm-footer .logos {
        flex-wrap: wrap;
        justify-content: center;
   }
    .template--vm-search article .section--footer-vm .vm-footer .logos > svg:nth-child(-n+2) {
        margin: 0 1em;
   }
    .template--vm-search article .section--footer-vm .vm-footer .logos .m-break {
        flex-basis: 100%;
        height: 10px;
   }
}
.template--vm-search article .section--footer-vm .vm-footer .logos > svg {
    transform: scale(0.8);
}
@media (max-width: 399px) {
    .template--vm-search article .section--footer-vm .vm-footer .logos > svg:nth-of-type(3) {
        margin: 0 1em;
   }
}
